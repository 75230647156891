/* Generated by Glyphter (http://www.glyphter.com) on  Wed May 06 2020*/
@font-face {
  font-family: 'iAM_pictos';
  src: url('/assets/fonts/iAM_pictos.otf');
  /*src: url('/assets/fonts/iAM_pictos.eot');
  src: url('/assets/fonts/iAM_pictos.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/iAM_pictos.woff') format('woff'),
       url('/assets/fonts/iAM_pictos.ttf') format('truetype'),
       url('/assets/fonts/iAM_pictos.svg#iAM_pictos') format('svg');*/
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before{
  display: inline-block;
  font-family: 'iAM_pictos';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}


.icon-actualites:before{content:'\0041';}
.icon-aide:before{content:'\0042';}
.icon-calendrier:before{content:'\0043';}
.icon-deconnexion:before{content:'\0044';}
.icon-drag-drop:before{content:'\0045';}
.icon-fermer:before{content:'\0046';}
.icon-fleche_droite:before{content:'\0047';}
.icon-fleche_gauche:before{content:'\0048';}
.icon-home:before{content:'\0049';}
.icon-infos_generales:before{content:'\004a';}
.icon-menu_burger:before{content:'\004b';}
.icon-modification:before{content:'\004c';}
.icon-ouverture_fermeture:before{content:'\004d';}
.icon-plus:before{content:'\004e';}
.icon-rechercher:before{content:'\004f';}
.icon-separateur_horizontal:before{content:'\0050';}
.icon-separateur_vertical:before{content:'\0051';}
.icon-services:before{content:'\0052';}
.icon-supprimer:before{content:'\0053';}
.icon-telecharger:before{content:'\0054';}
.icon-valider:before{content:'\0055';}
.icon-batterie:before{content:'\0056';}
.icon-calcul:before{content:'\0057';}
.icon-camion:before{content:'\0058';}
.icon-carte:before{content:'\0059';}
.icon-case:before{content:'\005a';}
.icon-ecolo:before{content:'\0061';}
.icon-enveloppe:before{content:'\0062';}
.icon-essence:before{content:'\0063';}
.icon-food:before{content:'\0064';}
.icon-gear:before{content:'\0065';}
.icon-lavage:before{content:'\0066';}
.icon-locator:before{content:'\0067';}
.icon-maison:before{content:'\0068';}
.icon-money:before{content:'\0069';}
.icon-pant:before{content:'\006a';}
.icon-paper:before{content:'\006b';}
.icon-photo_numerique:before{content:'\006c';}
.icon-photo:before{content:'\006d';}
.icon-pourcentage:before{content:'\006e';}
.icon-quali:before{content:'\006f';}
.icon-recharge:before{content:'\0070';}
.icon-reseau:before{content:'\0071';}
.icon-sage:before{content:'\0072';}
.icon-traiteur:before{content:'\0073';}
.icon-B:before{content:'\0074';}
.icon-centrer:before{content:'\0075';}
.icon-droite:before{content:'\0076';}
.icon-gauche:before{content:'\0077';}
.icon-grandir:before{content:'\0078';}
.icon-i:before{content:'\0079';}
.icon-img:before{content:'\007a';}
.icon-lien:before{content:'\0030';}
.icon-pastille:before{content:'\0031';}
.icon-resizer:before{content:'\0032';}
.icon-retrecir:before{content:'\0033';}
.icon-poste:before{content:'\0034';}
