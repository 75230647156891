@import '../abstracts/variables';

.h-bg-lt-grey {
  background-color: $grey1;
}

.h-blue-hover:hover {
  color: $lightBlue;
}

a.no-underline{
  text-decoration: inherit;
}
a.no-style{
  color: inherit;
  text-decoration: inherit;
}
.h-bg-orange {
  background-color: $bgOrange;
}

.btn-block{
  margin: 1em auto;
}
