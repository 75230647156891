@import '../abstracts/mixins',
'../abstracts/variables';

html {
  font-family: $mainFont;
  font-size: 55%;
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

input {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.c-asterisk {
  margin-top: 2rem;
  font-size: 1.6rem;
  color: $red;
}

label sup,
.c-card__infos sup {
  color: $red;
}

.resizeSVG {
  width: 4rem;
  display: block;
}

.website__content {
  padding-top: 4rem;
}

.website__title {
  font-weight: 300;
  line-height: 3.5rem;
  margin-bottom: 3.4rem;
}

.website__title-top {
  color: $darkBlue;
  font-size: 3.2rem;
  font-weight: inherit;
  text-transform: uppercase;
}

.website__title-bottom {
  color: $grey3;
  font-size: 2.4rem;
}

@include mobile-tablet {

  .website__header {
    min-height: 5.6rem;
  }

  .website__content {
    padding-bottom: 1.2rem;
    padding-top: 2rem;

    &--home {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  .website__title {
    text-align: center;
    line-height: 3.8rem;
  }

}

@include desktop {

  .galec-separateur {
    > [class*="col-"]:first-child {
      position: relative;

      &::after {
        content: '';
        background-image: linear-gradient(to right, $darkBlue 33%, rgba(255, 255, 255, 0) 0%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
        height: 0.2rem;
        width: 3rem;
        display: block;
        position: absolute;
        top: 50%;
        right: -1.5rem;
        transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 90deg);
      }
    }
  }

  .website__content {
    padding-bottom: 2rem;
    padding-top: 4.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 80%;
    margin: 0 auto;
  }

  .website__content > * {
    width: 100%;
  }

  .c-header-connection.connected {

    + .website__content {
      padding-bottom: 2rem;
      padding-top: 9.2rem !important;

      @include mobile-tablet {
        padding-top: 5.2rem;
      }
    }
  }

  .website__title {
    display: flex;
    align-items: center;
  }

  .website__title-top {
    margin-right: 1.5rem;
  }

}