/*Media queries*/

@mixin mobile {
  @media (max-width: #{$mq-mobile - 1px}) {
    @content;
  }
}

@mixin mobile-wide {
  @media (max-width: #{$mq-mobile-wide - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$mq-desktop - 1px}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$mq-mobile-wide}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$mq-desktop}) {
    @content;
  }
}
