/* VARIABLES (to be created only if used more than once) */

/*Media Queries*/
$mq-desktop: 1024px;
$mq-mobile: 415px;
$mq-mobile-wide: 768px;

/*Bootstrap grid classes definition*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: $mq-desktop
);

/*Creates spacing helpers*/

$spacer: 1.6rem;
$spacers: ();

$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3)
  ),
  $spacers
);

/*Fonts*/
$mainFont: 'Roboto', sans-serif;

/*Colors*/

$lightBlue:     #008BE7;
$darkBlue:      #00406A;
$orange:        #EB780C;
$bgOrange:      lighten(#EB780C, 45%);
$grey1:         #f4f4f4;
$grey2:         #DDDDDD;
$grey3:         #777777;
$boxShadowGrey: rgba(0, 0, 0, 0.2);
$red:           #EB0C0C;
$white:         #ffffff;
$green:         #006A00;
